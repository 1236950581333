import React from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"

import LocalizedLink from "../components/LocalizedLink"

import * as styles from "./CategoryBox.module.sass"

const CategoryBox = props => {
  // props
  const { category, onPaper = false } = props

  // wrapper classes
  const wrapperClasses = classNames({
    "is-flex": true,
    "is-justify-content-center": true,
    "is-align-items-center": true,
    [styles.categoryBox]: true,
    [styles.categoryBoxOnPaper]: onPaper,
  })

  return (
    <div className={wrapperClasses}>
      <div className="has-text-centered">
        <p>
          <FormattedMessage id={"general.see-more-in-category"} />
        </p>
        <LocalizedLink to={category.handle} className="button is-primary">
          {category.title}
        </LocalizedLink>
      </div>
    </div>
  )
}

export default CategoryBox
