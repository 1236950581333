import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedDate, useIntl } from "react-intl"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import { Parallax } from "react-parallax"
import fetch from "isomorphic-fetch"

import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import config from "../config/main"

import Layout from "../layouts"

import { goToSlideByNumber, getCarouselPlugins } from "../utils/carousel-utils"
import { getShopifyContentfulMatcher, getShopifyGidFromBase64 } from "../utils/product-matcher"
import { smartReplacements, hashString } from "../utils/text-properties"
import useWindowWidth from "../utils/window-width"

import DoubleBorderBox from "../components/DoubleBorderBox"
import LocalizedLink from "../components/LocalizedLink"
import ProductBox from "../components/ProductBox"
import SocialProfileLink from "../components/SocialProfileLink"
import CategoryBox from "../components/CategoryBox"

const HomePage = props => {
  // props
  const {
    data: {
      allContentfulHomepage: { nodes: homepages },
      woodBlanksFeaturedContentfulProducts: {
        nodes: featuredWoodBlanksContentful,
      },
      woodBlanksFeaturedShopifyProducts: { nodes: featuredWoodBlanksShopify },
      colorsFeaturedContentfulProducts: { nodes: featuredColorsContentful },
      colorsFeaturedShopifyProducts: { nodes: featuredColorsShopify },
      allContentfulBlogPost: { nodes: posts },
    },
    pageContext: { locale },
  } = props

  // hooks
  const { formatMessage } = useIntl()

  // get localized homepage
  const homepage = homepages.find(h => h.node_locale === locale)

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   */
  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        let slug = ""
        if (node.data.target.pageSlug) {
          slug = node.data.target.pageSlug
        }
        if (node.data.target.collectionSlug) {
          slug = node.data.target.collectionSlug
        }
        return (
          <LocalizedLink to={`/${slug}`}>{node.content[0].value}</LocalizedLink>
        )
      },
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  /**
   * get related product meta data if available
   * @param {Array} contentfulProducts
   * @param {Object} product
   * @param {String} locale
   * @returns
   */
  const getRelatedProductMetaData = (contentfulProducts, product, locale) => {
    return contentfulProducts.find(c => {
      return (
        product.variants.some(
          v => getShopifyContentfulMatcher(v, c)
        ) && c.node_locale === locale
      )
    })
  }

  // carousel (multiple)
  const [currentSlides, setCurrentSlides] = useState([0, 0, 0])

  const width = useWindowWidth()
  let numberOfProductSlidesShown = 3
  if (width <= 768) {
    numberOfProductSlidesShown = 2
  }
  if (width <= 480) {
    numberOfProductSlidesShown = 1
  }

  let numberOfPostSlidesShown = 3
  if (width <= 1023) {
    numberOfPostSlidesShown = 2
  }
  if (width <= 768) {
    numberOfPostSlidesShown = 1
  }

  // instagram
  const useInstagram = () => {
    const [posts, setPosts] = useState([])
    useEffect(() => {
      fetch("https://m99.io/posts.json")
        .then(res => res.json())
        .then(res =>
          res.data.user.edge_owner_to_timeline_media.edges.map(edge => {
            const fileName = edge.node.thumbnail_src
              .split("/")
              .pop()
              .split("?")
              .shift()
              .replace(".jpg", ".webp")
            return {
              thumbnail: `https://m99.io/instagram/${fileName}`,
              url: `https://instagram.com/p/${edge.node.shortcode}`,
              caption: edge.node.edge_media_to_caption.edges[0].node.text,
              id: edge.node.id,
            }
          })
        )
        .then(setPosts)
    }, [])
    return posts
  }

  const instagramPosts = useInstagram()

  // sort featured products
  // `featuredWoodBlanksShopify` by `woodBlanksFeaturedProducts`
  // `featuredColorsShopify` by `colorsFeaturedProducts`

  const woodBlanksSortOrder = homepage.woodBlanksFeaturedProducts.map(
    p => getShopifyGidFromBase64(p)
  )
  featuredWoodBlanksShopify.sort((a, b) => {
    return (
      woodBlanksSortOrder.indexOf(a.storefrontId) -
      woodBlanksSortOrder.indexOf(b.storefrontId)
    )
  })

  const featuredWoodBlankBoxes = featuredWoodBlanksShopify.map(
    (relatedProductVariant, index) => (
      <ProductBox
        area={relatedProductVariant.product.id}
        key={relatedProductVariant.product.id}
        product={{
          ...relatedProductVariant.product,
          handle:
            getRelatedProductMetaData(
              featuredWoodBlanksContentful,
              relatedProductVariant.product,
              locale
            )?.productSlug || relatedProductVariant.product.handle,
          title:
            getRelatedProductMetaData(
              featuredWoodBlanksContentful,
              relatedProductVariant.product,
              locale
            )?.productTitle || relatedProductVariant.product.title,
        }}
        locale={locale}
        onPaper={true}
        lazyLoading={index >= numberOfProductSlidesShown}
      />
    )
  )
  const woodBlankCategoryBox = (
    <CategoryBox
      key="wood-blank-category-box"
      onPaper={true}
      category={{
        handle: homepage.woodBlanksCtaDestination.collectionSlug,
        title: homepage.woodBlanksCtaLabel,
      }}
    />
  )

  const colorsSortOrder = homepage.colorsFeaturedProducts.map(
    p => getShopifyGidFromBase64(p)
  )
  featuredColorsShopify.sort((a, b) => {
    return (
      colorsSortOrder.indexOf(a.storefrontId) -
      colorsSortOrder.indexOf(b.storefrontId)
    )
  })

  const featuredColorBoxes = featuredColorsShopify.map(
    (relatedProductVariant, index) => (
      <ProductBox
        area={relatedProductVariant.product.id}
        key={relatedProductVariant.product.id}
        product={{
          ...relatedProductVariant.product,
          handle:
            getRelatedProductMetaData(
              featuredColorsContentful,
              relatedProductVariant.product,
              locale
            )?.productSlug || relatedProductVariant.product.handle,
          title:
            getRelatedProductMetaData(
              featuredColorsContentful,
              relatedProductVariant.product,
              locale
            )?.productTitle || relatedProductVariant.product.title,
        }}
        locale={locale}
        onPaper={false}
        lazyLoading={index >= numberOfProductSlidesShown}
      />
    )
  )
  const colorCategoryBox = (
    <CategoryBox
      key="color-category-box"
      onPaper={false}
      category={{
        handle: homepage.colorsCtaDestination.collectionSlug,
        title: homepage.colorsCtaLabel,
      }}
    />
  )

  /**
   * get localized posts with smart replacements
   * @param {Array} posts
   * @param {String} locale
   * @returns
   */
  const getLocalizedPosts = (posts, locale) => {
    return posts
      .map(post => ({
        ...post,
        postTitle: smartReplacements(post.postTitle, locale)
      }))
      .filter(post => post.node_locale === locale)
  }

  // limit posts to 3
  const localizedPosts = getLocalizedPosts(posts, locale).slice(0, 3)

  const featuredPostBoxes = localizedPosts.map((post, index) => {
    const path = `/blog/${post.postSlug}-${hashString(post.publicationDate)}`
    return (
      <div className="columns is-multiline is-align-context-flex-start is-relative px-0 py-0 mx-1 my-0" key={post.contentful_id}>
        <div className="column is-12">
          <LocalizedLink to={path}>
            <div className="card">
              {post.postHeaderImage && (<div className="card-image">
                <GatsbyImage
                  objectFit="contain"
                  loading={index >= numberOfPostSlidesShown ? "lazy" : "eager"}
                  alt={post.postTitle}
                  image={post.postHeaderImage.gatsbyImageData}
                />
              </div>)}
              <div className="card-content">
                <h2 className="subtitle">{post.postTitle}</h2>
                {post.postHeaderContentShort &&
                  renderRichText(post.postHeaderContentShort, renderOptions)}
                <p><small className="has-text-grey"><FormattedDate value={post.publicationDate} year="numeric" month="long" day="2-digit" /></small></p>
              </div>
            </div>
          </LocalizedLink>
        </div>
      </div>
    )
  })

  // feature flags
  const showBlogSection =
    typeof config.featureFlags.onHomepage.blog !== "undefined" &&
    config.featureFlags.onHomepage.blog === true

  return (
    <Layout description={formatMessage({ id: "meta.description" })}>
      {/* Header Image */}
      <section className="hero is-medium">
        <div className="container is-fluid px-0 is-header">
          <Parallax
            loading="eager"
            bgImage={getSrc(homepage.headerImage)}
            bgImageSrcSet={getSrcSet(homepage.headerImage)}
            bgImageAlt={formatMessage({ id: "meta.description" })}
            bgImageStyle={{ objectFit: "cover" }}
            strength={0}
          />
        </div>
      </section>

      {/* Claim */}
      <section className="has-background-paper is-relative">
        <div className="container is-fluid px-0 is-parallax is-parallax-claim">
          <DoubleBorderBox movedUp={true} treeOnTop={true}>
            <h2>{homepage.claimHeadline}</h2>
            {renderRichText(homepage.claimDescription, renderOptions)}
          </DoubleBorderBox>
          <Parallax
            loading="lazy"
            bgImage={getSrc(homepage.claimImage)}
            bgImageSrcSet={getSrcSet(homepage.claimImage)}
            bgImageAlt={homepage.claimHeadline}
            bgImageStyle={{ objectFit: "cover" }}
            strength={-150}
          />
        </div>
      </section>

      {/* Wood Blanks Feature */}
      <section className="section has-background-paper has-tree-white">
        <div className="container">
          <div className="columns">
            <div className="column is-three-fifths">
              <h2 className="subtitle">{homepage.woodBlanksHeadline}</h2>
              {renderRichText(homepage.woodBlanksDescription, renderOptions)}
            </div>
            <div className="column is-two-fifths has-text-right">
              <LocalizedLink
                to={homepage.woodBlanksCtaDestination.collectionSlug}
                className="button is-special"
              >
                {homepage.woodBlanksCtaLabel}
              </LocalizedLink>
            </div>
          </div>

          {typeof window !== "undefined" && Carousel && <Carousel
            plugins={getCarouselPlugins(
              currentSlides,
              setCurrentSlides,
              0,
              featuredWoodBlanksShopify.length + 1,
              numberOfProductSlidesShown,
              formatMessage({ id: "general.previous" }),
              formatMessage({ id: "general.next" })
            )}
            value={currentSlides[0]}
            onChange={index =>
              goToSlideByNumber(
                currentSlides,
                setCurrentSlides,
                0,
                featuredWoodBlanksShopify.length + 1,
                numberOfProductSlidesShown,
                index
              )
            }
          >
            {[...featuredWoodBlankBoxes, woodBlankCategoryBox]}
          </Carousel>}
        </div>
      </section>

      {/* Colors Feature */}
      <section className="section has-tree-paper-left">
        <div className="container">
          <div className="columns">
            <div className="column is-three-fifths">
              <h2 className="subtitle">{homepage.colorsHeadline}</h2>
              {renderRichText(homepage.colorsDescription, renderOptions)}
            </div>
            <div className="column is-two-fifths has-text-right">
              <LocalizedLink
                to={homepage.colorsCtaDestination.collectionSlug}
                className="button is-special"
              >
                {homepage.colorsCtaLabel}
              </LocalizedLink>
            </div>
          </div>

          {typeof window !== "undefined" && Carousel && <Carousel
            plugins={getCarouselPlugins(
              currentSlides,
              setCurrentSlides,
              1,
              featuredColorsShopify.length + 1,
              numberOfProductSlidesShown,
              formatMessage({ id: "general.previous" }),
              formatMessage({ id: "general.next" })
            )}
            value={currentSlides[1]}
            onChange={index =>
              goToSlideByNumber(
                currentSlides,
                setCurrentSlides,
                1,
                featuredColorsShopify.length + 1,
                numberOfProductSlidesShown,
                index
              )
            }
          >
            {[...featuredColorBoxes, colorCategoryBox]}
          </Carousel>}
        </div>
      </section>

      {/* Occasions */}
      <section className="is-relative">
        <div className="container is-fluid px-0 is-parallax is-large">
          <DoubleBorderBox>
            <h2>{homepage.occasionsHeadline}</h2>
            {renderRichText(homepage.occasionsDescription, renderOptions)}
            {homepage.occasionsCtaDestination && homepage.occasionsCtaLabel && (
              <Link
                className="button is-primary"
                to={homepage.occasionsCtaDestination}
              >
                {homepage.occasionsCtaLabel}
              </Link>
            )}
          </DoubleBorderBox>
          <Parallax
            loading="lazy"
            bgImage={getSrc(homepage.occasionsImage)}
            bgImageSrcSet={getSrcSet(homepage.occasionsImage)}
            bgImageAlt={homepage.occasionsHeadline}
            bgImageStyle={{ objectFit: "cover" }}
            strength={-150}
          />
        </div>
      </section>

      {/* Blog */}
      {showBlogSection && <React.Fragment>
        <section className="section has-tree-paper">
          <div className="container">
            <div className="columns">
              <div className="column is-three-fifths">
                <h2 className="subtitle">{homepage.blogHeadline}</h2>
                {renderRichText(homepage.blogDescription, renderOptions)}
              </div>
              <div className="column is-two-fifths has-text-right">
                <LocalizedLink
                  to="/blog"
                  className="button is-special"
                >
                  {homepage.blogCtaLabel}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            {typeof window !== "undefined" && Carousel && <Carousel
              plugins={getCarouselPlugins(
                currentSlides,
                setCurrentSlides,
                2,
                3,
                numberOfPostSlidesShown,
                formatMessage({ id: "general.previous" }),
                formatMessage({ id: "general.next" })
              )}
              value={currentSlides[2]}
              onChange={index =>
                goToSlideByNumber(
                  currentSlides,
                  setCurrentSlides,
                  0,
                  3,
                  numberOfPostSlidesShown,
                  index
                )
              }
            >
              {featuredPostBoxes}
            </Carousel>}
          </div>
        </section>
      </React.Fragment>}

      {/* Instagram */}
      <section className="section has-background-paper">
        <div className="container">
          <div className="columns">
            <div className="column is-three-fifths">
              <h2 className="subtitle">{homepage.socialHeadline}</h2>
              {renderRichText(homepage.socialDescription, renderOptions)}
            </div>
            <div className="column is-two-fifths has-text-right">
              {/* CTAs */}
              {homepage.socialProfiles.map(profile => (
                <SocialProfileLink key={profile.url} profile={profile} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-paper">
        <div className="container">
          <div className="columns is-mobile is-multiline">
            {instagramPosts.map(post => (
              <a
                href={post.url}
                target="_blank"
                rel="noopener noreferrer"
                key={post.id}
                className="column is-6-mobile is-4-tablet-only is-3-desktop"
                title={post.caption}
              >
                <figure className="image is-square">
                  <img
                    src={post.thumbnail}
                    alt={post.caption}
                    width="640"
                    height="640"
                  />
                </figure>
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* <pre>{JSON.stringify(woodBlanksFeaturedProductVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(colorsFeaturedProductVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(homepage, null, 2)}</pre> */}
    </Layout>
  )
}

export default HomePage

export const HomePageQuery = graphql`
  query (
    $woodBlanksFeaturedProductVariantIds: [String!]
    $woodBlanksFeaturedProductVariantIdsBase64: [String!]
    $colorsFeaturedProductVariantIds: [String!]
    $colorsFeaturedProductVariantIdsBase64: [String!]
  ) {
    allContentfulHomepage {
      nodes {
        node_locale
        headerImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        claimHeadline
        claimDescription {
          raw
        }
        claimImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        woodBlanksHeadline
        woodBlanksDescription {
          raw
          references {
            ... on ContentfulCollection {
              contentful_id
              collectionTitle
              collectionSlug
            }
          }
        }
        woodBlanksCtaLabel
        woodBlanksCtaDestination {
          ... on ContentfulCollection {
            contentful_id
            collectionTitle
            collectionSlug
          }
        }
        woodBlanksFeaturedProducts {
          ... on ContentfulProduct {
            node_locale
            productSlug
            productTitle
            shopifyProductVariantId
          }
        }
        colorsHeadline
        colorsDescription {
          raw
          references {
            ... on ContentfulCollection {
              contentful_id
              collectionTitle
              collectionSlug
            }
          }
        }
        colorsCtaLabel
        colorsCtaDestination {
          ... on ContentfulCollection {
            contentful_id
            collectionTitle
            collectionSlug
          }
        }
        colorsFeaturedProducts {
          ... on ContentfulProduct {
            node_locale
            productSlug
            productTitle
            shopifyProductVariantId
          }
        }
        occasionsHeadline
        occasionsDescription {
          raw
        }
        occasionsCtaLabel
        occasionsCtaDestination
        occasionsImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        blogHeadline
        blogDescription {
          raw
          references {
            ... on ContentfulPage {
              contentful_id
              pageTitle
              pageSlug
              pageType
            }
          }
        }
        blogCtaLabel
        socialHeadline
        socialDescription {
          raw
        }
        socialProfiles {
          ... on ContentfulSocialProfile {
            url
            type
          }
        }
      }
    }
    woodBlanksFeaturedShopifyProducts: allShopifyProductVariant(
      filter: { storefrontId: { in: $woodBlanksFeaturedProductVariantIds } }
    ) {
      nodes {
        storefrontId
        product {
          collections {
            id
            handle
            title
          }
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          hasOutOfStockVariants
          id
          images {
            altText
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              aspectRatio: 0.75
              breakpoints: [200, 300, 400]
            )
          }
          options {
            id
            name
            values
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          storefrontId
          title
          totalVariants
          variants {
            availableForSale
            compareAtPrice
            id
            price
            storefrontId
            title
          }
        }
      }
    }
    woodBlanksFeaturedContentfulProducts: allContentfulProduct(
      filter: {
        shopifyProductVariantId: { in: $woodBlanksFeaturedProductVariantIdsBase64 }
      }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
    colorsFeaturedShopifyProducts: allShopifyProductVariant(
      filter: { storefrontId: { in: $colorsFeaturedProductVariantIds } }
    ) {
      nodes {
        storefrontId
        product {
          collections {
            id
            handle
            title
          }
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          hasOutOfStockVariants
          id
          images {
            altText
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              aspectRatio: 0.75
              breakpoints: [200, 300, 400]
            )
          }
          options {
            id
            name
            values
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          storefrontId
          title
          totalVariants
          variants {
            availableForSale
            compareAtPrice
            id
            price
            storefrontId
            title
          }
        }
      }
    }
    colorsFeaturedContentfulProducts: allContentfulProduct(
      filter: {
        shopifyProductVariantId: { in: $colorsFeaturedProductVariantIdsBase64 }
      }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
    allContentfulBlogPost(
      sort: {order: DESC, fields: publicationDate}
    ) {
      nodes {
        contentful_id
        node_locale
        postSlug
        postTitle
        publicationDate
        postHeaderImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1280
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        postHeaderContentShort {
          raw
        }
      }
    }
  }
`
