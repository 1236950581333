import React from "react"
import { FormattedMessage } from "react-intl"
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTiktok,
  IoLogoTwitter,
} from "react-icons/io5"

export const SOCIAL_PROFILE_TYPE = {
  INSTAGRAM: "Instagram",
  PINTEREST: "Pinterest",
  TIKTOK: "Tiktok",
  TWITTER: "Twitter",
}

const LinkSkeleton = props => {
  // props
  const { profile, children } = props

  return (
    <a
      key={profile.url}
      href={profile.url}
      target="_blank"
      rel="noopener noreferrer"
      title={profile.type[0]}
      className="button is-special"
    >
      {children}
    </a>
  )
}

const SocialProfileLink = props => {
  // props
  const { profile } = props

  switch (profile.type[0]) {
    case SOCIAL_PROFILE_TYPE.INSTAGRAM:
      return (
        <LinkSkeleton profile={profile}>
          <span><FormattedMessage id={"general.follow-us"} /></span>
          <span className="icon ml-2">
            <IoLogoInstagram />
          </span>
        </LinkSkeleton>
      )
    case SOCIAL_PROFILE_TYPE.PINTEREST:
      return (
        <LinkSkeleton profile={profile}>
          <span className="icon">
            <IoLogoPinterest />
          </span>
        </LinkSkeleton>
      )
    case SOCIAL_PROFILE_TYPE.TIKTOK:
      return (
        <LinkSkeleton profile={profile}>
          <span className="icon">
            <IoLogoTiktok />
          </span>
        </LinkSkeleton>
      )
    case SOCIAL_PROFILE_TYPE.TWITTER:
      return (
        <LinkSkeleton profile={profile}>
          <span className="icon">
            <IoLogoTwitter />
          </span>
        </LinkSkeleton>
      )
    default:
      return <React.Fragment></React.Fragment>
  }
}

export default SocialProfileLink
